<template lang="pug">
  app-select(v-bind="$props" @input="$emit('input', $event)").app-combobox
    template(v-slot:field="{toggle}")
      .app-combobox__field
        slot(name="icon")
        input(
          :value="value"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="$emit('input', $event.target.value)"
          @click="toggle"
        )
</template>

<script>
import showingMixin from "@/mixins/showing.mixin";

export default {
  name: 'BaseCombobox',

  mixins: [ showingMixin ],

  props: {
    value: {
      required: false
    },
    text: Boolean,
    placeholder: String,
    label: String,
    options: {
      type: Array,
      required: true
    },
    slugKey: {
      type: String,
    },
    slugValue: {
      type: String
    },
    hideArrow: Boolean,
    error: Boolean,
    errorMessages: String,
    requiredField: Boolean,
    returnObject: Boolean,
    searchable: Boolean,
    placement: String,
    disabled: Boolean,
    boundariesElement: {
      type: [ String, Element ],
      default: '.app-combobox__field'
    }
  },

  components: {
    appSelect: () => import('./BaseSelect.vue'),
  }
}
</script>

<style lang="scss">
.app-combobox {
  &__field {
    display: flex;
    align-items: center;

    .material-icons {
      margin-right: 6px;
      color: $primary-color;
      font-size: 16px;
    }

    input {
      outline: none;
      color: $title-second;
      font-weight: 500;
      font-size: 12px;

      &::placeholder {
        color: #BBC4CA;
        font-size: 12px;
      }
    }
  }
}

.app-combobox.app-input_error {
  .app-combobox__field .material-icons {
    color: $error-color;
  }
}
</style>
